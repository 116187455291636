// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.app__main-container > *:not(:last-child) {
  margin-bottom: 50px;
}

.item-1 {
  background-color: rgb(118, 0, 173);
}
.item-2 {
  background-color: rgb(235, 63, 0);
}
.item-3 {
  background-color: rgb(4, 223, 212);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/slide/slide1.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,iCAAiC;AACnC;AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAiB;UAAjB,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".app__main-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.app__main-container > *:not(:last-child) {\n  margin-bottom: 50px;\n}\n\n.item-1 {\n  background-color: rgb(118, 0, 173);\n}\n.item-2 {\n  background-color: rgb(235, 63, 0);\n}\n.item-3 {\n  background-color: rgb(4, 223, 212);\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  user-select: none;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
