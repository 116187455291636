// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0%;
  margin: 0;
  border: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus-active {
  outline: none;
}
a:focus,
a:active {
  outline: non;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

a {
  text-decoration:none;
}

input, textarea {
  outline: none;
  border: none;
  border: solid 1px #f2f2f2;
  }

`, "",{"version":3,"sources":["webpack://./src/style/style-null.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,SAAS;AACX;AACA;;;EAKE,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;AACA;;EAEE,YAAY;AACd;AACA;;;;EAIE,cAAc;AAChB;AACA;;EAEE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,cAAc;EACd,eAAe;EACf,0BAA0B;EAC1B,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;;;EAGE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;AACA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,SAAS;AACX;AACA;;EAEE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA;;;;;;EAME,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB","sourcesContent":["* {\n  padding: 0%;\n  margin: 0;\n  border: 0;\n}\n*,\n*::before,\n*::after {\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n}\n\n:focus-active {\n  outline: none;\n}\na:focus,\na:active {\n  outline: non;\n}\nnav,\nfooter,\nheader,\naside {\n  display: block;\n}\nhtml,\nbody {\n  height: 100%;\n  width: 100%;\n  font-size: 100%;\n  line-height: 1;\n  font-size: 14px;\n  -ms-text-size-adjust: 100%;\n  -moz-text-size-adjust: 100%;\n  -webkit-text-size-adjust: 100%;\n}\n\ninput,\nbutton,\ntextarea {\n  font-family: inherit;\n}\n\ninput::-ms-clear {\n  display: none;\n}\nbutton {\n  cursor: pointer;\n}\n\nbutton::-moz-focus-inner {\n  padding: 0;\n  border: 0;\n}\na,\na:visited {\n  text-decoration: none;\n}\na:hover {\n  text-decoration: none;\n}\nul li {\n  list-style: none;\n}\nimg {\n  vertical-align: top;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-size: inherit;\n  font-weight: 400;\n}\n\na {\n  text-decoration:none;\n}\n\ninput, textarea {\n  outline: none;\n  border: none;\n  border: solid 1px #f2f2f2;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
