

const FAQPage = () => {
    return ( 
        <div className="FAQPage">
            <div className="container">
                FAQ
            </div>
        </div>
     );
}
 
export default FAQPage;


