// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 29px 0;
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 30px;
}

.all-pages-container {
  height: 100%;
  display: flex;
  transition: translate;
  transition-property: transform;
  /* transition-duration: 300ms; */
  transition-timing-function: ease-in-out;
}

.arrow {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/slideMainPage1/carousel-compound/Carousel2.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,gCAAgC;EAChC,uCAAuC;AACzC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".main-container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 29px 0;\n}\n\n.window {\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  padding: 30px;\n}\n\n.all-pages-container {\n  height: 100%;\n  display: flex;\n  transition: translate;\n  transition-property: transform;\n  /* transition-duration: 300ms; */\n  transition-timing-function: ease-in-out;\n}\n\n.arrow {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
