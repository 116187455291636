// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  padding-top: 20px;
  position: relative;
  margin: auto;
}

.background {
  position: absolute;
  top: 373px;
  left: 0;
  width: 100%;
  z-index: -99;
  opacity: 50%;
}

body {
  height: 100%;
  font-family: "Kanit", sans-serif;
  background: #1F2A3C;
}

.container {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 146%;
  letter-spacing: 0.045em;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/style/main.scss","webpack://./src/style/colors.scss"],"names":[],"mappings":"AAGA;EAEI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AAHJ;;AAQA;EACI,kBAAA;EACA,UAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AALJ;;AASA;EACI,YAAA;EACA,gCAAA;EACA,mBC3Be;ADqBnB;;AAWA;EACI,iBAAA;EACA,cAAA;EACA,eAAA;EAEA,aAAA;EACA,sBAAA;EACA,mBAAA;AATJ;;AAaA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,WC9CS;ADoCb","sourcesContent":["@import'./colors.scss';\n@import'./tamplates.scss';\n\n.App{\n    // max-width: 1440px;\n    width: 100%;\n    overflow-x: hidden;\n    position: relative;\n    padding-top: 20px;\n    position: relative;\n    margin: auto;\n    // height: 100%;\n}\n\n\n.background{\n    position: absolute;\n    top: 373px;\n    left: 0;\n    width: 100%;\n    z-index: -99;\n    opacity: 50%;\n\n}\n\nbody{\n    height: 100%;\n    font-family: 'Kanit', sans-serif;\n    background: $color_background;\n    // overflow-x: hidden;\n    // overflow: hidden;\n}\n\n.container{\n    max-width: 1260px;\n    margin: 0 auto;\n    padding: 0 30px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n}\n\nh2{\n    font-weight: 600;\n    font-size: 32px;\n    line-height: 146%;\n    letter-spacing: 0.045em;\n    color: $color_white;\n}\n\n\n\n\n\n\n","\n$color_background: #1F2A3C;\n$button_color:#0DE8D4;\n$color_white:#fff;\n$color_purple:#9c63f7;\n$color_gray:#3f4958;\n$color_bg_profile: rgba(63, 73, 88, 0.7);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
