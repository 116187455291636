// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_logo {
  width: 240px;
  height: 75px;
  margin-right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/style/logo.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;AACJ","sourcesContent":[".main_logo {\n    width: 240px;\n    height: 75px;\n    margin-right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
